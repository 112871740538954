import { useEffect, useState, Children, Fragment } from "react";
import dynamic from "next/dynamic";
import { useDispatch } from "react-redux";
import Link from "next/link";
import store from "@/redux/store";
import { useRouter } from "next/router";
import { Image as DatoImage } from "react-datocms";

// Import all required components
const MetaTags = dynamic(() => import("@/common/MetaTags"));
const StripeContainer = dynamic(() => import("@/components/Layout/StripeContainer"));
const MainSlider = dynamic(() => import("@/components/Sliders"));
const AbstractCard = dynamic(() => import("@/components/Abstract/Card"));
// const InfoCardTwoColumn = dynamic(() => import("@/components/Card/InfoCardTwoColumn"));
// const AbstractCardTwoColumn = dynamic(() => import("@/components/Abstract/CardTwoColumn"));
// const Contact = dynamic(() => import("@/components/Card/Contact"));
const ServiceCard = dynamic(() => import("@/components/Home/ServiceCard"));
const InstagramWidget = dynamic(() => import("@/components/InstagramWidget"));
const CircleWrapper = dynamic(() => import("@/components/Layout/CircleWrapper"));
const Divider = dynamic(() => import("@/components/Divider/Divider"));

// Import redux actions
import { fetchHomePagesData } from "@/redux/actions/homePageActions";
import {
  fetchLabelsAndConfigData,
  setLabelsConfigsData,
} from "@/redux/actions/labelsAndConfigActions";

// Import graphQL query variable
import { LABEL_AND_CONFIG_QUERY } from "@/graphql/settingsQuery";
import { HOMEPAGE_QUERY } from "@/graphql/homePageQuery";

export default function Home(props) {
  const router = useRouter();
  const { locale } = router;
  const { homePageData, eventsData, labelsConfigData } = props;

  const dispatch = useDispatch();
  const [labelsList, setLabelsList] = useState({});
  const [siteConfig, setSiteConfig] = useState({});
  const [showData, setShowData] = useState(false);

  useEffect(() => {
    // Set labelslist local state while changed on redux store data
    dispatch(setLabelsConfigsData(labelsConfigData));
    setLabelsList(labelsConfigData?.labelsList);
    setSiteConfig(labelsConfigData?.siteConfig);
  }, [dispatch, labelsConfigData]);

  useEffect(() => {
    setShowData(true);
  }, []);

  return (
    <>
      {homePageData && (
        <MetaTags
          metaTags={homePageData?._seoMetaTags}
          seoData={homePageData?.seo}
          noFollow={homePageData?.nofollow}
          noIndex={homePageData?.noindex}
        />
      )}
      {showData && (
        <CircleWrapper containerClass="flex flex-col gap-12 mt-6 sm:mt-12">
          <StripeContainer sectionHeightClass="">
            <MainSlider sliderData={homePageData?.gallery} />
          </StripeContainer>

          <div className="container my-0 sm:my-16">
            <AbstractCard
              username={homePageData?.author}
              title={homePageData?.subtitle}
              desc={homePageData?.description}
            />
          </div>

          <Divider />
          {Children.toArray(
            homePageData?.service.map((serviceItem, idx) => (
              <Fragment key={idx}>
                <ServiceCard serviceItem={serviceItem} idx={idx} locale={locale} />
                <Divider />
              </Fragment>
            )),
          )}

          {homePageData?.bannerCtaButtonLink && homePageData?.bannerCtaButtonLabel && (
            <>
              <div className="container">
                <div className="relative w-full h-[375px]">
                  <DatoImage
                    data={{
                      ...homePageData?.bannerBackgroundImage?.responsiveImage,
                      alt: `Cover Image for ${homePageData?.bannerCtaButtonLabel} button`,
                    }}
                    alt={`Cover Image for ${homePageData?.bannerCtaButtonLabel} button`}
                    lazyLoad={true}
                    objectFit="cover"
                    objectPosition="top"
                    layout="fill"
                    fadeInDuration={10}
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-70 flex flex-col justify-center items-center space-y-6">
                    <Link href={homePageData?.bannerCtaButtonLink} className="btn btn-primary">
                      {homePageData?.bannerCtaButtonLabel}
                    </Link>
                  </div>
                </div>
              </div>

              <Divider className="hidden sm:block" />
            </>
          )}

          <div className="container flex flex-col gap-12">
            <AbstractCard title={homePageData?.followOnSocialTitle} />
            <InstagramWidget
              title={eventsData?.title}
              labelsList={labelsList}
              showBullets={false}
            />
          </div>
        </CircleWrapper>
      )}
    </>
  );
}

export async function getStaticProps({ preview = false, locale = "en" }) {
  let requestParams = HOMEPAGE_QUERY?.replace(/\lang/g, locale);

  const homeResponse = await store.dispatch(fetchHomePagesData(requestParams, preview));

  const labelsConfigData = await store.dispatch(
    fetchLabelsAndConfigData(LABEL_AND_CONFIG_QUERY, preview, { lang: locale }),
  );

  return {
    props: {
      preview,
      homePageData: homeResponse?.home,
      contactData: homeResponse?.contact,
      labelsConfigData: labelsConfigData || {},
      eventsData: homeResponse?.eventsPage,
    },
  };
}
