export const CMS_NAME = "Flamel";
export const CMS_ENDPOINT = "https://graphql.datocms.com/";

export const SUPPORTED_LOCALES = ["en", "fr", "de", "it"];

export const POST_MEDIA_TYPES = {
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  CAROUSEL_ALBUM: "CAROUSEL_ALBUM",
};
