import { GraphQLClient } from "graphql-request";
import { CMS_ENDPOINT } from "@/constants/constants";

// Responsive Image Fragment setting
const responsiveImageFragment = `
  fragment responsiveImageFragment on ResponsiveImage {
    srcSet
    webpSrcSet
    sizes
    src
    width
    height
    aspectRatio
    alt
    title
    bgColor
    base64
  }
`;

// Do request on DatoCMS server for getting data as per request
export function request({ query, preview, variables }) {
  const client = new GraphQLClient(CMS_ENDPOINT + (preview ? "/preview" : ""), {
    headers: {
      authorization: `Bearer ${process.env.DATOCMS_API_TOKEN}`,
      "X-Environment": process.env.DATO_ENV,
    },
  });
  if (query?.indexOf("$slug") > -1) {
    query = `query GetDataBySlug($slug: String) ${query} `;
  }
  // Replace currentDate variable with today's date
  if (query?.indexOf("$date") > -1) {
    query = `query GetDataBySlug($date: Date) ${query} `;
  }

  let graphqlRequest = {
    query: `${query}`,
  };

  // Replace or do changes for reponsice image
  if (query.indexOf("responsiveImageFragment") > -1) {
    graphqlRequest = {
      query: `${query}
      ${responsiveImageFragment}
      `,
    };
  }
  return client.request(`${graphqlRequest?.query}`, {
    preview,
    slug: variables?.slug ? variables?.slug : null,
    date: variables?.date ? variables?.date : null,
  });
}
