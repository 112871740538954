import {
  START_FETCH_LABELS_CONFIG_LIST,
  SUCCESS_FETCH_LABELS_CONFIG_LIST,
  ERROR_FETCH_LABELS_CONFIG_LIST,
} from "./labelsAndConfigActionsTypes";

import { fetchCMSDataQuery } from "@/services/requestService";

/**
 * This function used for getting modal and labels list as well as getting site config data
 * @param {*} requestParams
 */
export function fetchLabelsAndConfigData(requestParams, preview, variables) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(fetchLabelsAndConfigDataStart());
      let updatedRequestParams = requestParams?.replace(/\lang/g, variables?.lang);
      fetchCMSDataQuery(updatedRequestParams, preview)
        .then((data) => {
          dispatch(fetchLabelsAndConfigDataSuccess(data));
          resolve(data);
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(fetchLabelsAndConfigDataFailure("A network error occurred"));
          reject(err);
        });
    });
  };
}

function fetchLabelsAndConfigDataStart() {
  return {
    type: START_FETCH_LABELS_CONFIG_LIST,
  };
}

function fetchLabelsAndConfigDataSuccess(labelsConfigData) {
  return {
    type: SUCCESS_FETCH_LABELS_CONFIG_LIST,
    labelsConfigData,
  };
}

function fetchLabelsAndConfigDataFailure(error) {
  return {
    type: ERROR_FETCH_LABELS_CONFIG_LIST,
    error,
  };
}

export const setLabelsConfigsData = (labelsConfigData) => {
  return {
    type: SUCCESS_FETCH_LABELS_CONFIG_LIST,
    labelsConfigData,
  };
};
